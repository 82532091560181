import React, {useState, useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
    _t,
    getCookie,
    modalLoading,
    tableLoading,
    pagination,
    paginationLoading,
    showingData,
    searchedShowingData,
} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";

//axios and base url
import axios from "axios";
import {BASE_URL} from "../../../../../BaseUrl";

//3rd party packages
import {Helmet} from "react-helmet";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import {SettingsContext} from "../../../../../contexts/Settings";
import {FoodContext} from "../../../../../contexts/Food";
import {toBeChecked} from "@testing-library/jest-dom";

const GroupCrud = () => {
    const {t} = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let {
        //food group
        getFoodGroup,
        foodGroupList,
        setFoodGroupList,
        setPaginatedFoodGroup,
        foodGroupForSearch,
        setFoodGroupforSearch,

        //pagination
        dataPaginating,
    } = useContext(FoodContext);

    // States hook here
    //new group
    let [newFoodGroup, setNewFoodGroup] = useState({
        name: "",
        exclude_print: false,
        edit: false,
        editSlug: null,
        uploading: false,
    });

    //search result
    let [searchedFoodGroup, setSearchedFoodGroup] = useState({
        list: null,
        searched: false,
    });

    //useEffect == componentDidMount
    useEffect(() => {
    }, []);

    //set name hook
    const handleSetNewFoodGroup = (e) => {
        setNewFoodGroup({...newFoodGroup, [e.target.name]: e.target.value});

    };

    //set Exclude Print hook
    const handleCheckExcludePrint = (e) => {
        setNewFoodGroup({...newFoodGroup, [e.target.exclude_print]: e.target.checked});
    }

    //Save New paymentType
    const handleSaveNewFoodGroup = (e) => {
        e.preventDefault();
        setNewFoodGroup({
            ...newFoodGroup,
            uploading: true,
        });
        const foodGroupUrl = BASE_URL + `/settings/new-food-group`;
        let formData = new FormData();
        formData.append("name", newFoodGroup.name)
        formData.append("exclude_print", newFoodGroup.exclude_print)
        return axios
            .post(foodGroupUrl, formData, {
                headers: {Authorization: `Bearer ${getCookie()}`},
            })
            .then((res) => {
                setNewFoodGroup({
                    name: "",
                    exclude_print: false,
                    edit: false,
                    editSlug: null,
                    uploading: false,
                });
                setFoodGroupList(res.data[0]);
                setFoodGroupforSearch(res.data[1]);
                setSearchedFoodGroup({
                    ...searchedFoodGroup,
                    list: res.data[1],
                });
                setLoading(false);
                toast.success(`${_t(t("Food group has been added"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            })
            .catch((error) => {
                setLoading(false);
                setNewFoodGroup({
                    ...newFoodGroup,
                    uploading: false,
                });
                if (error.response.data.errors) {
                    if (error.response.data.errors.name) {
                        error.response.data.errors.name.forEach((item) => {
                            if (item === "A food group already exists with this name") {
                                toast.error(
                                    `${_t(t("A food group already exists with this name"))}`,
                                    {
                                        position: "bottom-center",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        className: "text-center toast-notification",
                                    }
                                );
                            }
                        });
                    } else {
                        toast.error(`${_t(t("Please try again"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                    }
                }
            });
    };

    //set edit true & values
    const handleSetEdit = (slug) => {
        let paymentType = foodGroupForSearch.filter((item) => {
            return item.slug === slug;
        });
        setNewFoodGroup({
            ...newFoodGroup,
            name: paymentType[0].name,
            exclude_print: paymentType[0].exclude_print,
            input_key: paymentType[0].input_key,
            editSlug: paymentType[0].slug,
            edit: true,
        });
    };

    //update food group
    const handleUpdateFoodGroup = (e) => {
        e.preventDefault();
        setNewFoodGroup({
            ...newFoodGroup,
            uploading: true,
        });
        const foodGroupUrl = BASE_URL + `/settings/update-food-group`;
        let formData = new FormData();
        formData.append("name", newFoodGroup.name);
        formData.append("exclude_print", newFoodGroup.exclude_print);
        formData.append("editSlug", newFoodGroup.editSlug);
        return axios
            .post(foodGroupUrl, formData, {
                headers: {Authorization: `Bearer ${getCookie()}`},
            })
            .then((res) => {
                setNewFoodGroup({
                    name: "",
                    exclude_print: null,
                    edit: false,
                    editSlug: null,
                    uploading: false,
                });
                setFoodGroupList(res.data[0]);
                setFoodGroupforSearch(res.data[1]);
                setSearchedFoodGroup({
                    ...searchedFoodGroup,
                    list: res.data[1],
                });
                setLoading(false);
                toast.success(`${_t(t("Food group has been updated"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            })
            .catch((error) => {
                setLoading(false);
                setNewFoodGroup({
                    ...newFoodGroup,
                    uploading: false,
                });
                if (error.response.data.errors) {
                    if (error.response.data.errors.name) {
                        error.response.data.errors.name.forEach((item) => {
                            if (item === "A food group already exists with this name") {
                                toast.error(
                                    `${_t(t("A food group already exists with this name"))}`,
                                    {
                                        position: "bottom-center",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        className: "text-center toast-notification",
                                    }
                                );
                            }
                        });
                    } else {
                        toast.error(`${_t(t("Please try again"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                    }
                }
            });
    };

    //search food group here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedFoodGroup({...searchedFoodGroup, searched: false});
        } else {
            let searchedList = foodGroupForSearch.filter((item) => {
                let lowerCaseItemName = item.name.toLowerCase();
                return lowerCaseItemName.includes(searchInput);
            });
            setSearchedFoodGroup({
                ...searchedFoodGroup,
                list: searchedList,
                searched: true,
            });
        }
    };

    //delete confirmation modal of paymentType
    const handleDeleteConfirmation = (slug) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">{_t(t("You want to delete this?"))}</p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleDeleteFoodGroup(slug);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, delete it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    //delete paymentType here
    const handleDeleteFoodGroup = (slug) => {
        setLoading(true);
        const foodGroupUrl = BASE_URL + `/settings/delete-food-group/${slug}`;
        return axios
            .get(foodGroupUrl, {
                headers: {Authorization: `Bearer ${getCookie()}`},
            })
            .then((res) => {
                setFoodGroupList(res.data[0]);
                setFoodGroupforSearch(res.data[1]);
                setSearchedFoodGroup({
                    ...searchedFoodGroup,
                    list: res.data[1],
                });
                setLoading(false);
                toast.success(`${_t(t("Food group has been deleted successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Food Groups"))}</title>
            </Helmet>

            {/* Add modal */}
            <div className="modal fade" id="addPaymentType" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {!newFoodGroup.edit
                                        ? _t(t("Add new food group"))
                                        : _t(t("Update food group"))}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {/* show form or show saving loading */}
                            {newFoodGroup.uploading === false ? (
                                <div key="fragment-food-group-1">
                                    <form
                                        onSubmit={
                                            !newFoodGroup.edit
                                                ? handleSaveNewFoodGroup
                                                : handleUpdateFoodGroup
                                        }
                                    >
                                        <div>
                                            <label htmlFor="name" className="form-label">
                                                {_t(t("Name"))}{" "}
                                                <small className="text-primary">*</small>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="e.g. Burger, Chicken"
                                                value={newFoodGroup.name || ""}
                                                required
                                                onChange={handleSetNewFoodGroup}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="exclude_print" className="form-label">
                                                {_t(t("exclude_print"))}{" "}
                                                <small className="text-primary">*</small>
                                            </label>
                                                <input
                                                    type="checkbox"
                                                    className="ml-2"
                                                    id="exclude_print"
                                                    name="exclude_print"
                                                    checked={newFoodGroup.exclude_print}
                                                    value={newFoodGroup.exclude_print || false}
                                                    onChange={e => setNewFoodGroup({...newFoodGroup,[e.target.name]:e.target.checked})}
                                                />
                                        </div>


                                        <div className="mt-4">
                                            <div className="row">
                                                <div className="col-6">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                    >
                                                        {!newFoodGroup.edit
                                                            ? _t(t("Save"))
                                                            : _t(t("Update"))}
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                        data-dismiss="modal"
                                                    >
                                                        {_t(t("Close"))}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <div key="fragment2">
                                    <div className="text-center text-primary font-weight-bold text-uppercase">
                                        {_t(t("Please wait"))}
                                    </div>
                                    {modalLoading(3)}
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    {!newFoodGroup.edit ? _t(t("Save")) : _t(t("Update"))}
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                    data-dismiss="modal"
                                                >
                                                    {_t(t("Close"))}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Add modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar/>
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                        {newFoodGroup.uploading === true || loading === true ? (
                                            tableLoading()
                                        ) : (
                                            <div key="fragment3">
                                                {/* next page data spin loading */}
                                                <div className={`${dataPaginating && "loading"}`}></div>
                                                {/* spin loading ends */}

                                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                        <ul className="t-list fk-breadcrumb">
                                                            <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedFoodGroup.searched
                                      ? _t(t("Food Group List"))
                                      : _t(t("Search Result"))}
                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 col-lg-7">
                                                        <div className="row gx-3 align-items-center">
                                                            {/* Search group */}
                                                            <div className="col-md-9 t-mb-15 mb-md-0">
                                                                <div className="input-group">
                                                                    <div className="form-file">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control border-0 form-control--light-1 rounded-0"
                                                                            placeholder={_t(t("Search")) + ".."}
                                                                            onChange={handleSearch}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="button"
                                                                    >
                                                                        <i
                                                                            className="fa fa-search"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {/* Add group modal trigger button */}
                                                            <div className="col-md-3 text-md-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                                                    data-toggle="modal"
                                                                    data-target="#addPaymentType"
                                                                    onClick={() => {
                                                                        setNewFoodGroup({
                                                                            ...newFoodGroup,
                                                                            edit: false,
                                                                            uploading: false,
                                                                        });
                                                                    }}
                                                                >
                                                                    {_t(t("add new"))}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Table */}
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-hover min-table-height">
                                                        <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("S/L"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Name"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Action"))}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {!searchedFoodGroup.searched
                                                            ? [
                                                                foodGroupList && [
                                                                    foodGroupList.data.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="6"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        foodGroupList.data.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="align-middle"
                                                                                        key={index}
                                                                                    >
                                                                                        <th
                                                                                            scope="row"
                                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                                        >
                                                                                            {index +
                                                                                                1 +
                                                                                                (foodGroupList.current_page -
                                                                                                    1) *
                                                                                                foodGroupList.per_page}
                                                                                        </th>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {item.name}
                                                                                        </td>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            <div className="dropdown">
                                                                                                <button
                                                                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                                                                    type="button"
                                                                                                    data-toggle="dropdown"
                                                                                                >
                                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                                </button>
                                                                                                <div
                                                                                                    className="dropdown-menu">
                                                                                                    <button
                                                                                                        className="dropdown-item sm-text text-capitalize"
                                                                                                        onClick={() =>
                                                                                                            handleSetEdit(
                                                                                                                item.slug
                                                                                                            )
                                                                                                        }
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#addPaymentType"
                                                                                                    >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                        {_t(t("Edit"))}
                                                                                                    </button>

                                                                                                    <button
                                                                                                        className="dropdown-item sm-text text-capitalize"
                                                                                                        onClick={() => {
                                                                                                            handleDeleteConfirmation(
                                                                                                                item.slug
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                                                                        {_t(t("Delete"))}
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    ),
                                                                ],
                                                            ]
                                                            : [
                                                                /* searched data, logic === haveData*/
                                                                searchedFoodGroup && [
                                                                    searchedFoodGroup.list.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="6"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        searchedFoodGroup.list.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="align-middle"
                                                                                        key={index}
                                                                                    >
                                                                                        <th
                                                                                            scope="row"
                                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                                        >
                                                                                            {index +
                                                                                                1 +
                                                                                                (foodGroupList.current_page -
                                                                                                    1) *
                                                                                                foodGroupList.per_page}
                                                                                        </th>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {item.name}
                                                                                        </td>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            <div className="dropdown">
                                                                                                <button
                                                                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                                                                    type="button"
                                                                                                    data-toggle="dropdown"
                                                                                                >
                                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                                </button>
                                                                                                <div
                                                                                                    className="dropdown-menu">
                                                                                                    <button
                                                                                                        className="dropdown-item sm-text text-capitalize"
                                                                                                        onClick={() =>
                                                                                                            handleSetEdit(
                                                                                                                item.slug
                                                                                                            )
                                                                                                        }
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#addPaymentType"
                                                                                                    >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                        {_t(t("Edit"))}
                                                                                                    </button>

                                                                                                    <button
                                                                                                        className="dropdown-item sm-text text-capitalize"
                                                                                                        onClick={() => {
                                                                                                            handleDeleteConfirmation(
                                                                                                                item.slug
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                                                                        {_t(t("Delete"))}
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    ),
                                                                ],
                                                            ]}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* pagination loading effect */}
                            {newFoodGroup.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedFoodGroup.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(foodGroupList, setPaginatedFoodGroup)}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(foodGroupList)}
                                  </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() =>
                                                                    setSearchedFoodGroup({
                                                                        ...searchedFoodGroup,
                                                                        searched: false,
                                                                    })
                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                      searchedFoodGroup,
                                      foodGroupForSearch
                                  )}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default GroupCrud;
