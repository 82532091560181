// config
let production = 'NO';//YES ==live NO ==localhost
export let SAAS_APPLICATION = 'NO'; //YES|NO

// default config
export const saas_key = '$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu';
export const saas_apiUrl = 'https://api.franksbar.it';

export let BASE_URL = '';
export let saas_apiParams = '';
export let saas_form_data = null;


BASE_URL='https://api.franksbar.it/public'
