import React, {useState, useContext, useRef} from "react";
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaidIcon from '@mui/icons-material/Paid';
import PrintIcon from '@mui/icons-material/Print';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import ReactToPrint, {useReactToPrint,PrintContextConsumer } from 'react-to-print';

import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close';

//components
import PrintComponent from './components/PrintComponent'
import SingleCard from './components/SingleCard'


//functions
import {
    _t,
    getCookie,
    modalLoading,
    tableLoading,
    pagination,
    paginationLoading,
    showingData,
    searchedShowingData,
    formatPrice,
    currencySymbolLeft,
    currencySymbolRight, getSystemSettings,
} from "../../../../functions/Functions";
import {useTranslation} from "react-i18next";

//3rd party packages
import {Helmet} from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import {OrdersPendingContext} from "../../../../contexts/OrdersPending";
import {BASE_URL} from "../../../../BaseUrl";
import axios from "axios";
import {toast} from "react-toastify";

const OrdersPending = () => {
    let {
        loading,
        ordersPendingByTableList,
        //pagination
        dataPaginating,
    } = useContext(OrdersPendingContext);
    const [openViewMode, setOpenViewMode] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [itemPaid, setItemPaid] = useState();
    const [itemPrint, setItemPrint] = useState();
    const [selectedItem, setSelectedItem] = useState();
    let componentRef = useRef([]);

    let calcPrice = (item) => {
        let t = 0;
        return item.reduce((accumulator, object) => accumulator + parseInt(object.order_bill), t);
    };

    const handleViewMode = (_data) => {
        setOpenViewMode(true);
        setSelectedItem(_data);
    };
    // const handlePrint = async (_data) => {
    //     setItemPrint(_data)
    //     handlePrint2();
    //     console.log('its work')
    // };
    // const handlePrint2 = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const handlePaid = (_item) => {
        const url = BASE_URL + '/settings/get-ready-orders';
        axios.post(url, _item, {
            headers: {Authorization: `Bearer ${getCookie()}`},
        }).then((res) => {
            if (res.status === 200) {
                setOpenDialog(false);
                toast.success("updated successfully", {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                window.location.reload();
            } else {
                toast.error("Try again", {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }

        }).catch(() => {
            console.log('Error on data')
        });
    };
    const handleClose = () => {
        setOpenViewMode(false);
    };
    const open = (item) => {
        setOpenDialog(true);
        setItemPaid(item)
    };
    const close = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Helmet>
                <title>Orders Pending</title>
            </Helmet>


            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-15 g-0">
                        {ordersPendingByTableList && [
                            ordersPendingByTableList.length === 0 ? (
                                //Empty Orders
                                <div className="col-lg-6 col-xxl-6">
                                    <div className="t-bg-white">
                                        <div className="fk-scroll--pos-menu" data-simplebar>
                                            <div className="t-pl-15 t-pr-15">
                                                {/* Loading effect */}
                                                {loading === true ? (
                                                    tableLoading()
                                                ) : (
                                                    <div key="fragment3">
                                                        {/* next page data spin loading */}
                                                        <div className={`${dataPaginating && "loading"}`}></div>
                                                        {/* spin loading ends */}
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="row bg-danger">
                                                                    <div className="col-12">
                                                                        <span className="text-dark">Customer: no customer data</span><br/>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <span
                                                                            className="text-dark">Table: no table data </span>
                                                                    </div>
                                                                </div>
                                                                <div className="table-responsive">
                                                                    <table
                                                                        className="table table-bordered table-hover min-table-height">
                                                                        <thead className="align-middle">
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                            >
                                                                                <span>S/L</span>
                                                                            </th>

                                                                            <th
                                                                                scope="col"
                                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                            >
                                                                                <span>Product name</span>
                                                                            </th>

                                                                            <th
                                                                                scope="col"
                                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                            >
                                                                                <span>Additional info</span>
                                                                            </th>

                                                                            <th
                                                                                scope="col"
                                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                            >
                                                                                <span>Qty</span>
                                                                            </th>

                                                                            <th
                                                                                scope="col"
                                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                            >
                                                                                <span>Price</span>
                                                                            </th>

                                                                        </tr>
                                                                        </thead>
                                                                        <tbody className="align-middle">
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="6"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                <span>No data available</span>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-right mr-4">
                                            <span>Grand Total</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                //not empty
                                Object.entries(ordersPendingByTableList).map((item, index) => {
                                    return (
                                        <SingleCard item={item} key={index}/>
                                    );

                                })
                            )
                        ]}
                    </div>
                </div>
            </main>


        </>
    );
};

export default OrdersPending;

