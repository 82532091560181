import React, {useState, useEffect} from "react";

//axios and base url
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

//functions
import {getCookie} from "../functions/Functions";
import _ from "lodash";

//3rd party packages

// creating context api
const OrdersPendingContext = React.createContext();

const OrdersPendingProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [dataPaginating, setDataPaginating] = useState(false);
    //order pending by customer
    const [ordersPendingByCustomerList, setOrdersPendingListByCustomerList] = useState(null);

    //order pending by table
    const [ordersPendingByTableList, setOrdersPendingListByTableList] = useState(null);

    //useEffect- to get data on render
    useEffect(() => {
        //call if authenticated
        if (getCookie() !== undefined) {
            getOrdersPendingByTable();

        }
    }, []);

    //get OrdersPending by customer

    //get OrdersPending by table
    const getOrdersPendingByTable = async () => {
        // setLoading(true);
        const ordersPendingUrl = BASE_URL + "/settings/get-pending-orders";
        return await axios
            .get(ordersPendingUrl, {
                headers: {Authorization: `Bearer ${getCookie()}`},
            }).then((res) => {
                let result = _.groupBy(res.data, 'table_name');
                setOrdersPendingListByTableList(result);
            });


    };


    return (
        <OrdersPendingContext.Provider
            value={{
                // common
                loading,
                setLoading,


                //orders pending by customer
                ordersPendingByCustomerList,
                setOrdersPendingListByCustomerList,

                //orders pending by table
                getOrdersPendingByTable,
                ordersPendingByTableList,
                setOrdersPendingListByTableList,


                //pagination
                dataPaginating,
                setDataPaginating,
            }}
        >
            {children}
        </OrdersPendingContext.Provider>
    );
};

export {OrdersPendingContext, OrdersPendingProvider};
