import React from 'react';
import {_t, formatPrice, getSystemSettings} from "../../../../../functions/Functions";


const PrintComponent = React.forwardRef((props, ref) => {
    console.log(props)
    const _data = props.data
    const table_name= props.table_name
    const total_price = props.total_price
    return (
        <div className="d-none">
            <div ref={ref}>
                <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text">Franks</span>
                <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text">Table Name{table_name}</span>
                {_data.map((el) => {
                    return (
                    <div className="fk-print">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="myBorder">
                                        <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                                            Item Ordered
                                        </p></div>
                                    <table className="table mb-0 table-borderless">
                                        <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="fk-print-text xsm-text text-capitalize"
                                            >
                                                Qty Item
                                            </th>
                                            <th
                                                scope="col"
                                                className="fk-print-text xsm-text text-capitalize"
                                            >
                                                Price
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {el.order_item.map((it)=>{
                                            return (
                                                <tr>
                                                    <td className="fk-print-text xsm-text text-capitalize">
                                                            <span className="d-inline-block xsm-text">{it.quantity}</span>*
                                                            <span className="d-inline-block xsm-text mr-1">{it.food_item}</span>
                                                    </td>
                                                    <td className="fk-print-text xsm-text text-capitalize">
                                                            <span className="d-inline-block xsm-text">{it.price}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    <p className="mt-4 sm-text fk-print-text text-left text-capitalize">
                                        Order Bill {el.order_bill}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>)
                })}
                <p className="mt-4 sm-text fk-print-text text-left text-capitalize">
                    Grand Total {total_price}
                </p>
            </div>
        </div>
    );
});

export default PrintComponent
