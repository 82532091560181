import React, {useContext, useRef, useState} from 'react';
import {getCookie, tableLoading} from "../../../../../functions/Functions";
import ReactToPrint from "react-to-print";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import PrintComponent from "./PrintComponent";
import moment from "moment/moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaidIcon from "@mui/icons-material/Paid";
import {OrdersPendingContext} from "../../../../../contexts/OrdersPending";
import {BASE_URL} from "../../../../../BaseUrl";
import axios from "axios";
import {toast} from "react-toastify";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


const SingleCard = React.forwardRef((props, ref) => {
    let {
        loading,
        //pagination
        dataPaginating,
    } = useContext(OrdersPendingContext);
    const [openViewMode, setOpenViewMode] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [itemPaid, setItemPaid] = useState();
    const [itemPrint, setItemPrint] = useState();
    const [selectedItem, setSelectedItem] = useState();
    let componentRef = useRef();

    let calcPrice = (item) => {
        let t = 0;
        return item.reduce((accumulator, object) => accumulator + parseInt(object.order_bill), t);
    };

    const handleViewMode = (_data) => {
        setOpenViewMode(true);
        setSelectedItem(_data);
    };
    // const handlePrint = async (_data) => {
    //     setItemPrint(_data)
    //     handlePrint2();
    //     console.log('its work')
    // };
    // const handlePrint2 = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const handlePaid = (_item) => {
        const url = BASE_URL + '/settings/get-ready-orders';
        axios.post(url, _item, {
            headers: {Authorization: `Bearer ${getCookie()}`},
        }).then((res) => {
            if (res.status === 200) {
                setOpenDialog(false);
                toast.success("updated successfully", {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                window.location.reload();
            } else {
                toast.error("Try again", {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }

        }).catch(() => {
            console.log('Error on data')
        });
    };
    const handleClose = () => {
        setOpenViewMode(false);
    };
    const open = (item) => {
        setOpenDialog(true);
        setItemPaid(item)
    };
    const close = () => {
        setOpenDialog(false);
    };

    const item = props.item
    return (
        <div className="col-lg-12 col-xxl-12 ml-2 mb-5">
            <div className="t-bg-white">
                <div className="fk-scroll--pos-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                        {/* Loading effect */}
                        {loading === true ? (
                            tableLoading()
                        ) : (
                            <div key="fragment3">
                                {/* next page data spin loading */}
                                <div className={`${dataPaginating && "loading"}`}></div>
                                {/* spin loading ends */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-2">
                                                                                <span
                                                                                    className="text-dark">Table: {item[0]} </span>
                                            </div>
                                            <div className="col-3">
                                                <ReactToPrint
                                                    trigger={() =>
                                                        <IconButton aria-label="print" color="warning" size="small">
                                                            <PrintIcon/>
                                                        </IconButton>}
                                                    content={() => componentRef}
                                                />
                                                <PrintComponent
                                                    ref={el => (componentRef = el)}
                                                    data={item[1]}
                                                    total_price={calcPrice(item[1])}
                                                    table_name={item[0]}
                                                />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table
                                                className="table table-bordered table-hover min-table-height">
                                                <thead className="align-middle">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                                    >
                                                        <span>S/L</span>
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                                    >
                                                        <span>Customer Name</span>
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                                    >
                                                        <span>Ordered At</span>
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                                    >
                                                        <span>Order Price</span>
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                                    >
                                                        <span>Actions</span>
                                                    </th>

                                                </tr>
                                                </thead>
                                                <tbody className="align-middle">
                                                {item[1].map((_data, key) => {
                                                    return (
                                                        <tr className="align-middle"
                                                        >
                                                            <th
                                                                scope="row"
                                                                className="xsm-text text-capitalize align-middle text-center"
                                                            >{_data.id}</th>

                                                            {_data.customer ? (
                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                    {_data.customer.name}
                                                                </td>
                                                            ) : (
                                                                <td className="xsm-text text-capitalize align-middle text-center">-</td>
                                                            )}

                                                            <td className="xsm-text text-capitalize align-middle text-center">
                                                                {moment(_data.created_at).format("HH:mm:ss")}
                                                            </td>

                                                            <td className="xsm-text text-capitalize align-middle text-center">
                                                                {_data.order_bill}
                                                            </td>

                                                            <td className="xsm-text text-capitalize align-middle text-center">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-12">
                                                                        <IconButton
                                                                            aria-label="view"
                                                                            color="success"
                                                                            size="small"
                                                                            onClick={() => handleViewMode(_data.order_item)}>
                                                                            <VisibilityIcon/>
                                                                        </IconButton>
                                                                    </div>

                                                                    <div
                                                                        className="col-12">
                                                                        <IconButton
                                                                            aria-label="Paid"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => open(_data)}>
                                                                            <PaidIcon/>
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="text-right mx-5">
                    <span>Grand total {calcPrice(item[1])}</span>
                </div>
            </div>
            {/*Dialog view Order*/}
            <Dialog onClose={handleClose} open={openViewMode} hideBackdrop PaperProps={{elevation: 0, sx: {border: "solid 1px white", width: "800px"}}}>
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Item Ordered
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className="col-lg-12 col-xxl-12">
                        <div className="t-bg-white">
                            <div className="fk-scroll--pos-menu" data-simplebar>
                                <div className="t-pl-0 t-pr-0">
                                    {/* Loading effect */}
                                    {loading === true ? (
                                        tableLoading()
                                    ) : (
                                        <div key="fragment3">
                                            {/* next page data spin loading */}
                                            <div className={`${dataPaginating && "loading"}`}></div>
                                            {/* spin loading ends */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table table-bordered table-hover min-table-height">
                                                            <thead className="align-middle">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                >
                                                                    <span>ID</span>
                                                                </th>

                                                                <th
                                                                    scope="col"
                                                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                >
                                                                    <span>Item Name</span>
                                                                </th>

                                                                <th
                                                                    scope="col"
                                                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                >
                                                                    <span>Price</span>
                                                                </th>

                                                                <th
                                                                    scope="col"
                                                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                >
                                                                    <span>Quantity</span>
                                                                </th>


                                                            </tr>
                                                            </thead>
                                                            <tbody className="align-middle">
                                                            {selectedItem && selectedItem.map((it, ind) => {
                                                                return (
                                                                    <tr className="align-middle"
                                                                    >
                                                                        <th
                                                                            scope="row"
                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                        >
                                                                        </th>

                                                                        <td className="xsm-text">
                                                                            {it.food_item}
                                                                        </td>

                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                            {it.price}
                                                                        </td>

                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                            {it.quantity}
                                                                        </td>

                                                                    </tr>
                                                                );
                                                            })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}} maxWidth="xs" open={openDialog}>
                <DialogTitle>Are you sure want Paid ?</DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={close}>
                        Cancel
                    </Button>
                    <Button onClick={() => handlePaid(itemPaid)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>

    );
});

export default SingleCard
